import {useLayoutEffect, useState} from 'react';
import {onAuthStateChanged, User} from "firebase/auth";
import {doc, getDoc} from "firebase/firestore";
import {auth, db} from "./firebase";


export function useUser(): User | undefined {
  const [user, setUser] = useState();
  useLayoutEffect(() => {
    onAuthStateChanged(auth, async (user: any) => {
      if (user) {
        setUser(user);
      }
    });
  }, []);
  return user;
}

export function useCurrentUsage(uid: string | undefined) {
  const [usage, setUsage] = useState<any>();

  useLayoutEffect(() => {
    (async () => {
      if (uid) {
        const docRef = await getDoc(doc(db, `usage/${uid}`));
        setUsage(docRef.data());
      }
    })();
  }, [uid]);

  return usage;
}