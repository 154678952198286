import React, { HTMLAttributes, useLayoutEffect } from 'react';
import s from './VerifyEmail.module.scss';
import {Dot, Loader} from "./Logo";
import {navigate} from 'gatsby';
import {useUser} from "../utils/user";
import {track} from "../utils/analytics";
import {onAuthStateChanged, User } from 'firebase/auth';
import {auth} from "../utils/firebase";
import {postLoginFlow} from "../utils/intent";
import {sendVerificationEmail} from "../utils/authentication";
import {wait} from "../utils";

const RETRY_DURATION = 10000; // 10s
const MAX_RETRIES = 20;
async function startRecheckLoop(user: User) {
  let tries = 0;
  let duration = RETRY_DURATION;

  while (tries <= MAX_RETRIES) {
    await user.reload();
    if (user.emailVerified) {
      return postLoginFlow(user);
    } else {
      await wait(duration);
    }
    tries++;
    if (tries > 6) {
      duration *= 2; // double duration after each try
    }
  }
}

export interface VerifyEmailProps extends HTMLAttributes<HTMLFormElement> {
  onBack?: () => any;
}

export function VerifyEmail({}: VerifyEmailProps) {
  const user = useUser();

  useLayoutEffect(() => {
    // @ts-ignore
    onAuthStateChanged(auth, (user: User) => {
      if (!user) {
        track('redirected_to_signin', { from: 'verify-email' });
        navigate('/signin');
      } else {
        if (user.emailVerified) {
          return postLoginFlow(user);
        } else {
          return startRecheckLoop(user);
        }
      }
    });
  }, []);

  return <div className={s.verifyEmail}>
    <h1>Check Your Inbox<Dot/></h1>
    <p>
      We just sent a verification link to <u>{user?.email}</u><br/>
      Click it to verify your email.
    </p>

    <p style={{ padding: 0 }}>Didn't get an email?</p>
    <button className='secondary' onClick={() => user && sendVerificationEmail(user)}>Resend it</button>
    {!user && <Loader/>}
  </div>
}